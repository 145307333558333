import text from './text';
import image from './image';
import video from './video';
import button from './button';
import email_form from './email_form';
import coca_form from './coca_form';
import carousel from './carousel';
import tabs from './tabs';
import products_list from './products_list';
import form from './form';
import iframe from './iframe';
import i18n from './i18n';
import gql from "graphql-tag";
import wheel from './wheel';
import plan from './plan';
import quizz from './quizz';
import button_diffuse from './button_diffuse';

export default {
  i18n,
  video,
  image,
  text,
  button,
  email_form,
  coca_form,
  carousel,
  tabs,
  products_list,
  form,
  wheel,
  iframe,
  plan,
  quizz,
  button_diffuse,
  create: gql`
    mutation CreateCell ($input: CreateCellInput!) {
      createCell (input: $input) {
        id
      }
    }
  `,

  listForPage: gql`
    query Cells($pageId: ID!) {
      cells(id: $id) {
        id
        project_id
        name
      }
    }
  `,

  updateCells: gql`
    mutation UpdateCells ($input: [UpdateCellInput]!) {
      updateCells (input: $input) {
        id
        links_to_page_id
        links_to_language
        is_back_link
      }
    }
  `,

  updateCellsCoordinates: gql`
    mutation UpdateCellsCoordinates ($input: [UpdateCellCoordinatesInput]!, $screenTypeId: ID!) {
      updateCellsCoordinates (input: $input, screenTypeId: $screenTypeId) {
        x
        y
        width
        height
      }
    }
  `,

  deleteCell: gql`
    mutation DeleteCell ($input: ID!) {
      deleteCell (input: $input) {
        id
      }
    }
  `,
}
