import React from "react";
import ImageCellEditor from "./ImageCellEditor";
import CommonCellEditor from "./CommonCellEditor";
import TextCellEditor from "./TextCellEditor";
import VideoCellEditor from "./VideoCellEditor";
import ButtonCellEditor from "./ButtonCellEditor";
import EmailFormCellEditor from "./EmailFormCellEditor";
import NeedAdmin from "../../NeedAdmin";
import CarouselCellEditor from "./CarouselCellEditor";
import TabMenuCellEditor from "./TabMenuCellEditor";
import CocaFormCellEditor from "./CocaFormCellEditor";
import ProductsListCellEditor from "./ProductsListCellEditor";
import FormCellEditor from "./FormCellEditor";
import IframeCellEditor from "./IframeCellEditor";
import WheelCellEditor from "./WheelCellEditor"
import PlanCellEditor from "./PlanCellEditor";
import ButtonDiffuseCellEditor from "./ButtonDiffuseCellEditor";

class CellEditor extends React.Component  {

  specificEditor = (cell, language, screenType) => {

    switch (cell.cell_type) {
      case "image":
        return (
          <NeedAdmin>
            <ImageCellEditor cell={cell} language={language} />
          </NeedAdmin>
        );
      case "button_diffuse":
        return (
          <NeedAdmin>
            <ButtonDiffuseCellEditor cell={cell} screenType={screenType} language={language} />
          </NeedAdmin>
        );
      case "text":
        return <TextCellEditor cell={cell} language={language} />;
      case "button":
        console.log("button", cell);
        return (
          <NeedAdmin>
            <ButtonCellEditor cell={cell} language={language} />
          </NeedAdmin>
        );
      case "video":
        return (
          <NeedAdmin>
            <VideoCellEditor cell={cell} language={language} />
          </NeedAdmin>
        );
      case "form":
        return (
          <NeedAdmin>
            <FormCellEditor cell={cell} language={language} screenType={screenType} />
          </NeedAdmin>
        );
      case "email_form":
        return (
          <NeedAdmin>
            <EmailFormCellEditor cell={cell} language={language} screenType={screenType} />
          </NeedAdmin>
        );
      case "coca_form":
        return (
          <NeedAdmin>
            <CocaFormCellEditor cell={cell} language={language} />
          </NeedAdmin>
        );
      case "carousel":
        return (
          <NeedAdmin>
            <CarouselCellEditor cell={cell} language={language} screenType={screenType}/>
          </NeedAdmin>
        );
      case "tab_menu":
        return (
          <NeedAdmin>
            <TabMenuCellEditor cell={cell} language={language} />
          </NeedAdmin>
        );
      case "products_list":
        return (
          <ProductsListCellEditor cell={cell} />
        );
      case "iframe":
        return (
          <NeedAdmin>
            <IframeCellEditor cell={cell} language={language} screenType={screenType}/>
          </NeedAdmin>
        );
        case"wheel":
      return(
          <NeedAdmin>
            <WheelCellEditor cell={cell} language={language} screenType={screenType}/>
          </NeedAdmin>
        );
        case'plan':
          return(
            <NeedAdmin>
              <PlanCellEditor cell={cell} language={language} screenType={screenType}/>
            </NeedAdmin>
        );
      default:
        return null
    }
  };

  implementsCommon = (cell) => {
    switch (cell.cell_type) {
      case "separator":
      case "language_selection":
      case "tab_menu":
      case "products_list":
      case "memory_game":
      case'plan':
        return false;
      default:
        return true
    }
  };

  render() {
    const { cell, screenType } = this.props;

    if (!cell) return null;

    if(this.implementsCommon(cell)) {

      return (
        <CommonCellEditor cell={cell} screenType={screenType}>
          {(language) => this.specificEditor(cell, language, screenType)}
        </CommonCellEditor>
      )
    }
    return this.specificEditor(cell, null, screenType);

  }
}

export default CellEditor;
